<template>
  <div v-if="composePractice && composePractice.id > 0">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SatManagement' }">
            View
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ `Adaptive Tests - ${PageTitle}` }}
          （<b v-if="composePractice.exam.is_public === 1">Published</b>
          <b v-else>Drafts</b>）
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="text-right">
      <div class="title-box-button">
        <template v-if="isEdit">
          <span v-if="this.id && Number(this.id) > 0">
            <el-tooltip
              class="item ml-2"
              effect="dark"
              content="Cancel"
              placement="top"
            >
              <router-link
                :to="{
                  name: $route.name,
                  params: {
                    ...$route.params
                  },
                  query: {
                    ...$route.query,
                    isEdit: 0
                  }
                }"
              >
                <el-button type="danger" size="small" plain>
                  Cancel
                  <i class="fas fa-undo-alt"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </span>
        </template>
        <template v-else>
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="Edit"
            placement="top"
          >
            <router-link
              :to="{
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  isEdit: 1
                }
              }"
            >
              <el-button type="success" size="small">
                Edit
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </template>

        <el-tooltip
          v-show="composePractice.exam.is_public === 1"
          class="ml-2"
          effect="dark"
          content="Change to draft"
          placement="top"
        >
          <el-button
            type="danger"
            size="small"
            @click="updateInfo({ is_public: 0 })"
          >
            Change to draft
            <i class="fas fa-level-down-alt"></i>
          </el-button>
        </el-tooltip>
        <el-tooltip
          v-if="composePractice.scorable_id && composePractice.scorable_id > 0"
          v-show="composePractice.exam.is_public === 0"
          class="ml-2"
          effect="dark"
          content="Publish"
          placement="top"
        >
          <el-button
            type="success"
            size="small"
            @click="updateInfo({ is_public: 1 })"
          >
            Publish
            <i class="fas fa-upload"></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <hr />
    <el-form :model="composePractice" label-width="100px">
      <el-form-item>
        <div slot="label">
          Name
        </div>
        <div>
          <el-input
            v-model="adaptive.exam.title"
            :disabled="!isEdit"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <div slot="label">
          Modules
        </div>
        <el-alert type="success" :closable="false" class="mb-3">
          <h6 class="m-2">
            <!-- Modules: -->
            <span
              :class="
                composePractice.compose_practice_practices.length < 6
                  ? 'text-danger'
                  : ''
              "
            >
              {{ composePractice.compose_practice_practices.length }}
            </span>
            / 6
          </h6>
        </el-alert>
        <template>
          <div>
            <h5 class="text-success">English Module 1</h5>
            <div v-if="adaptive.practices[0]">
              <PracticeCard
                :practice="adaptive.practices[0]"
                :order="1"
                :isEdit="isEdit"
                :composePracticeId="composePractice.id"
                :removeDisabled="!isEdit || hasTaken"
                @removeSection="removeSection(0)"
              ></PracticeCard>
            </div>
            <div v-else class="empty-card" @click="addSection(0, 1, 1)">
              <div class="plus-icon">
                <i class="fa fa-plus"></i>
              </div>
            </div>
            <div class="text-center mt-3 mb-4">
              <b class="line"> </b>
            </div>
            <div class="text-center mt-3">
              <h6>
                正確題數
              </h6>
              <div>
                <el-input v-model="adaptive.first_correct_count" type="number" style="width: 150px;"> </el-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6 line-box">
                <b class="line-style line-left">
                  小於 ＜ {{ adaptive.first_correct_count }}
                </b>
              </div>
              <div class="col-sm-6 line-box">
                <b class="line-style line-right">
                  大於等於 ≥ {{ adaptive.first_correct_count }}
                </b>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 mt-3">
                <h5 class="text-success">English Module 2</h5>
              </div>
              <div class="col-sm-6">
                <h5>Essay</h5>
                <div v-if="adaptive.practices[1]">
                  <PracticeCard
                    :practice="adaptive.practices[1]"
                    :order="2"
                    :isEdit="isEdit"
                    :composePracticeId="composePractice.id"
                    :removeDisabled="!isEdit || hasTaken"
                    @removeSection="removeSection(1)"
                  ></PracticeCard>
                </div>
                <div v-else class="empty-card" @click="addSection(1, 2, 1)">
                  <div class="plus-icon">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <h5>Hard</h5>
                <div v-if="adaptive.practices[2]">
                  <PracticeCard
                    :practice="adaptive.practices[2]"
                    :order="2"
                    :isEdit="isEdit"
                    :composePracticeId="composePractice.id"
                    :removeDisabled="!isEdit || hasTaken"
                    @removeSection="removeSection(2)"
                  ></PracticeCard>
                </div>
                <div v-else class="empty-card" @click="addSection(2, 2, 2)">
                  <div class="plus-icon">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="row mt-4 mb-5">
          <div class="col-sm-6 line-box">
            <b class="line-style line-left bottom"></b>
          </div>
          <div class="col-sm-6 line-box">
            <b class="line-style line-right bottom"></b>
          </div>
        </div>
        <template>
          <div>
            <h5 class="text-success">Math Module 1</h5>
            <div v-if="adaptive.practices[3]">
              <PracticeCard
                :practice="adaptive.practices[3]"
                :order="1"
                :isEdit="isEdit"
                :composePracticeId="composePractice.id"
                :removeDisabled="!isEdit || hasTaken"
                @removeSection="removeSection(3)"
              ></PracticeCard>
            </div>
            <div v-else class="empty-card" @click="addSection(3, 3, 1)">
              <div class="plus-icon">
                <i class="fa fa-plus"></i>
              </div>
            </div>
            <div class="text-center mt-3 mb-4">
              <b class="line"> </b>
            </div>
            <div class="text-center mt-3">
              <h6>
                正確題數
              </h6>
              <div>
                <el-input v-model="adaptive.last_correct_count" type="number" style="width: 150px;"> </el-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6 line-box">
                <b class="line-style line-left">
                  正確題數 ＜ {{ adaptive.last_correct_count }}
                </b>
              </div>
              <div class="col-sm-6 line-box">
                <b class="line-style line-right">
                  正確題數 ≥ {{ adaptive.last_correct_count }}
                </b>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 mt-3">
                <h5 class="text-success">Math Module 2</h5>
              </div>
              <div class="col-sm-6">
                <h5>Essay</h5>
                <div v-if="adaptive.practices[4]">
                  <PracticeCard
                    :practice="adaptive.practices[4]"
                    :order="2"
                    :isEdit="isEdit"
                    :composePracticeId="composePractice.id"
                    :removeDisabled="!isEdit || hasTaken"
                    @removeSection="removeSection(4)"
                  ></PracticeCard>
                </div>
                <div v-else class="empty-card" @click="addSection(4, 4, 1)">
                  <div class="plus-icon">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <h5>Hard</h5>
                <div v-if="adaptive.practices[5]">
                  <PracticeCard
                    :practice="adaptive.practices[5]"
                    :order="2"
                    :isEdit="isEdit"
                    :composePracticeId="composePractice.id"
                    :removeDisabled="!isEdit || hasTaken"
                    @removeSection="removeSection(5)"
                  ></PracticeCard>
                </div>
                <div v-else class="empty-card" @click="addSection(5, 4, 2)">
                  <div class="plus-icon">
                    <i class="fa fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-form-item>
      <el-form-item>
        <hr />
        <div class="text-center">
          <el-button type="success" @click="createAdaptive">
            <i class="fa fa-save"></i>
            儲存
          </el-button>
          <el-button type="success" @click="createAdaptiveAndFullTests">
            <i class="fa fa-save"></i>
            儲存並創建 4 個新的Full Tests
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog
      class="add-passage-dialog"
      :visible.sync="showCreateNewSectionDialog"
      :close-on-click-modal="false"
      width="60%"
      top="2vh"
    >
      <div slot="title">
        Add Module:
        <kbd>{{ composePractice.compose_practice_practices.length + 1 }}</kbd>
      </div>
      <template>
        <div class="questions-list">
          <el-form
            :model="newTest"
            ref="form"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item :label="$t('AddSubject.Method')">
              <el-radio-group v-model="addMode">
                <el-radio-button :label="1">
                  {{ $t("AddSubject.Choose from an existing subject") }}
                </el-radio-button>
                <el-radio-button :label="0">
                  {{ $t("AddSubject.Create a new module") }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <template v-if="addMode === 0">
              <el-form-item label="Subject" required prop="subject">
                <el-radio-group v-model="newTest.subject">
                  <el-radio-button
                    v-for="subject in subjectsNow"
                    :key="subject.id"
                    :label="subject.id"
                  >
                    {{ titleCase(subject.name) }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Name" required prop="title">
                <el-input
                  v-model="newTest.title"
                  placeholder="Name"
                  maxlength="100"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="success"
                  style=""
                  @click="addNewPracticeToFull"
                >
                  Create
                </el-button>
              </el-form-item>
            </template>
          </el-form>
          <div v-if="addMode === 1">
            <hr />
            <div class="text-center" style="margin:15px 0">
              <el-pagination
                background
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="9"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
            <el-input
              class="mb-3"
              :placeholder="$t('AddSubject.Type keyword here')"
              v-model="keyWord"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchPractices"
              >
                Search
              </el-button>
            </el-input>
            <div class="table-box">
              <el-table :data="practices" border :key="isUpdate" center>
                <el-table-column prop="title" label="Module Name">
                  <template slot-scope="scope">
                    <router-link
                      style="padding: 3px 0"
                      :to="{
                        name: 'SatPracticeDetail',
                        query: { id: scope.row.id }
                      }"
                    >
                      <i class="fas fa-eye" />
                      <b> {{ scope.row.exam.title }}</b>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="Questions" width="100">
                  <template slot-scope="scope">
                    <b>{{ scope.row.exam.exam_questions_count }}</b>
                  </template>
                </el-table-column>
                <el-table-column label="Action" width="120">
                  <template slot-scope="scope">
                    <el-button v-show="false"></el-button>
                    <el-button v-show="scope.row.isIn" size="small" disabled>
                      Added
                    </el-button>
                    <el-button
                      v-show="!scope.row.isIn"
                      @click="addPassage(scope.$index)"
                      type="success"
                      size="small"
                    >
                      <i class="fa fa-plus"></i> Add
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="text-center" style="margin:15px 0">
              <el-pagination
                background
                @current-change="setPage"
                :page-size="pageSize"
                :current-page="page"
                :total="resultsTotal"
                :pager-count="9"
                layout="prev, pager, next"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      class="add-passage-dialog"
      :visible.sync="showCreateFullTest"
      :close-on-click-modal="false"
      width="80%"
      top="2vh"
    >
      <div slot="title">
        Create Full Tests
      </div>
      <div>
        <el-table
          :data="[
            {
              name: 'NENE Tests',
              em1: 'English Module 1',
              em2: 'English Module 2 (Essay)',
              mm1: 'Math Module 1',
              mm2: 'Math Module 2 (Essay)'
            },
            {
              name: 'NENH Tests',
              em1: 'English Module 1',
              em2: 'English Module 2 (Essay)',
              mm1: 'Math Module 1',
              mm2: 'Math Module 2 (Hard)'
            },
            {
              name: 'NHNH Tests',
              em1: 'English Module 1',
              em2: 'English Module 2 (Hard)',
              mm1: 'Math Module 1',
              mm2: 'Math Module 2 (Hard)'
            },
            {
              name: 'NHNE Tests',
              em1: 'English Module 1',
              em2: 'English Module 2 (Hard)',
              mm1: 'Math Module 1',
              mm2: 'Math Module 2 (Essay)'
            }
          ]"
        >
          <el-table-column label="Name" prop="Name">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>

          <el-table-column label="English">
            <el-table-column label="Module 1" prop="em1"> </el-table-column>
            <el-table-column label="Module 2" prop="em2"> </el-table-column>
          </el-table-column>
          <el-table-column label="Math">
            <el-table-column label="Module 1" prop="mm1"> </el-table-column>
            <el-table-column label="Module 2" prop="mm2"> </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreateFullTest = false">取 消</el-button>
        <el-button type="success" @click="showCreateFullTest = false">
          確 認
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import PracticeCard from "@/views/satComposePractice/components/Practice.vue";
import draggable from "vuedraggable";
import { instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, PracticeCard },

  mixins: [Common],

  props: [],
  data() {
    return {
      showCreateFullTest: false,
      isUpdate: false,
      addMode: 1,
      showCreateNewSectionDialog: false,
      breakTime: [],
      isEdit: false,
      adaptive: {
        first_correct_count: 0,
        last_correct_count: 0,
        exam: {
          title: ""
        },
        practices: [null, null, null, null, null, null]
      },
      active: {
        index: null,
        order: null,
        type: null
      },
      composePractice: {
        test_id: null,
        test_type: "",
        exam: {
          title: null
        },
        compose_practice_practices: []
      },
      newTest: {
        isNew: null,
        type: "practice",
        subject: null,
        title: ""
      },
      practices: [],
      subjects: [],
      subject_id: null,
      keyWord: "",
      pageSize: 0,
      page: 0,
      resultsTotal: 0
    };
  },
  computed: {
    hasTaken() {
      return (
        this.composePractice.exam.user_exams_count &&
        this.composePractice.exam.user_exams_count > 0
      );
    },
    rules() {
      return {
        subject: [
          {
            required: true
          }
        ],
        title: [
          {
            required: true
          }
        ]
      };
    },
    subjectsNow() {
      let subjects = [];
      // if (this.newTest.isNew === 1) {
      //   subjects = [this.subjects[4], this.subjects[2]];
      // } else {
      //   subjects = [
      //     this.subjects[0],
      //     this.subjects[1],
      //     this.subjects[2],
      //     this.subjects[3]
      //   ];
      // }
      subjects = this.subjects;
      return subjects;
    },
    PageTitle() {
      let title = this.adaptive.exam.title
        ? this.adaptive.exam.title
        : "Edit";
      return title;
    },
    instant() {
      return instant;
    },
    id() {
      return this.$route.query.id;
    }
  },
  watch: {},

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    await this.getPractices();
    if (this.id && Number(this.id) > 0) {
      await this.getComposePracticeDetail(0);
      if (this.$route.query.isEdit) {
        this.isEdit = Number(this.$route.query.isEdit);
      }
      this.checkSections();
    } else {
      this.isEdit = true;
    }
  },

  methods: {
    async checkSections() {
      this.practices.forEach(practice => {
        practice["isIn"] = false;
      });
      this.adaptive.practices.forEach(section => {
        if (section) {
          this.practices.forEach(practice => {
            if (section.id === practice.id) {
              practice["isIn"] = true;
            }
          });
        }
      });
      this.isUpdate = !this.isUpdate;
    },
    async updateInfo(data) {
      await SAT.updateComposePractice(this.id, {
        compose_practice: {
          exam: {
            title: this.composePractice.exam.title,
            ...data
          }
        }
      });
      await this.getComposePracticeDetail();
    },
    async createAdaptive() {
      const res = await SAT.createAdaptive(this.adaptive);
    },
    async createAdaptiveAndFullTests() {
      // const res = await SAT.createAdaptive(this.adaptive);
      this.showCreateFullTest = true;
    },
    async addNewPracticeToFull() {
      try {
        let valid = await this.$refs.form.validate();

        if (!valid) {
          return false;
        }
      } catch (e) {
        return e;
      }
      const res = await SAT.createPractice({
        practice: {
          exam: {
            title: this.newTest.title,
            is_public: 0
          },
          subject_type: "Modules\\SAT\\Entities\\SatSubject",
          subject_id: this.newTest.subject,
          is_new: this.newTest.isNew
        }
      });
      await this.addSectionWithId(res.practice.id);
      this.showCreateNewSectionDialog = false;
    },
    addSection(index, order, type) {
      this.showCreateNewSectionDialog = true;
      this.active.index = index;
      this.active.order = order;
      this.active.type = type;
    },
    // async addSectionWithId(id) {
    //   let arr = [];
    //   this.composePractice.compose_practice_practices.forEach((item, index) => {
    //     arr.push({
    //       practice_id: item.id,
    //       order: index + 1,
    //       break_time: 0
    //     });
    //   });

    //   arr.push({
    //     practice_id: id,
    //     order: arr.length + 1,
    //     break_time: 0
    //   });
    //   // await SAT.updateComposePracticePractices(this.id, {
    //   //   compose_practice_practices: arr
    //   // });
    //   // this.showCreateNewSectionDialog = false;
    //   // await this.getComposePracticeDetail();
    //   // this.newTest.title = "";
    //   // setTimeout(() => {
    //   //   this.showCreateNewSectionDialog = true;
    //   // }, 500);
    // },
    async removeSection(order) {
      console.log(order);
      this.adaptive.practices[order] = null;
      this.checkSections();
    },
    async addPassage(index) {
      this.adaptive.practices[this.active.index] = {
        ...this.practices[index],
        order: this.active.order,
        type: this.active.type
      };
      this.showCreateNewSectionDialog = false;
      this.checkSections();
    },
    searchPractices() {
      this.page = 1;
      this.getPractices();
    },
    async getPractices() {
      this.practices = [];
      const res = await SAT.getPractices({
        page: this.page,
        keyword: this.keyWord,
        subject_id: this.subject_id
      });
      this.practices = res.practices.data;
      this.page = res.practices.current_page;
      this.resultsTotal = res.practices.total;
      this.pageSize = res.practices.per_page;
      this.checkSections();
    },
    searchPassages() {
      this.getPractices();
    },
    setPage(page) {
      this.page = page;
      this.getPractices();
    },
    async getComposePracticeDetail(type = 1) {
      if (this.isEdit && type === 1) {
        this.$notify({
          title: "Automatic saving successful!",
          type: "success"
        });
      }
      const res = await SAT.getComposePracticeDetail(this.id);
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
      if (this.composePractice.marks[0].name.en === "new") {
        this.newTest.isNew = 1;
      }
    }
  }
};
</script>

<style scoped>
.empty-card {
  width: 100%;
  height: 180px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.line {
  display: block;
  width: 20px;
  height: 100px;
  background-color: var(--themeColor);
  margin: 0 auto;
  border-radius: 4px;
  position: relative;
}

.line::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0px;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid var(--themeColor);
}
.line-box {
  height: 80px;
}
.line-style {
  display: flex;
  width: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80px;
  background-color: var(--themeColor);
  margin: 0 auto;
  color: white;
  border-radius: 4px;
}
.line-style::before {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0px;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid var(--themeColor);
}
.line-left {
  transform: translateY(-30px) translateX(80px) rotate(45deg);
  height: 150px;
}
.line-left.bottom {
  transform: translateY(-30px) translateX(80px) rotate(-45deg);
}
.line-right {
  transform: translateY(-30px) translateX(-80px) rotate(-45deg);
  height: 150px;
}
.line-right.bottom {
  transform: translateY(-30px) translateX(-80px) rotate(45deg);
}
.plus-icon {
  font-size: 70px;
  color: #ccc;
}
.title-box {
  display: flex;
  align-items: center;
}
.title-box .title-box-input {
  width: 50%;
}
::v-deep .el-form-item__content {
  line-height: 1.5;
}
.question-card {
  cursor: move;
}
.questions-list {
  padding: 1rem;
  /* border: 2px solid var(--themeColor); */
}
.table-box {
  height: 500px;
  overflow: auto;
}
</style>
